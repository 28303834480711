import React from 'react';
import App from 'next/app';
// import NProgress from "next-nprogress/component";
// import withNProgress from "next-nprogress";
import { withRouter } from 'next/router'
import axios from 'axios';
import { ToastContainer } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GET, POST } from "../helpers";
// import * as Sentry from '@sentry/browser';

// import { SENTRY_DSN } from '../vars'


import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css'
import '../node_modules/animate.css/animate.min.css'

import '../static/css/picxy.css'
import '../static/css/styles_picxy.min.css'
import '../static/css/book_photographer_style.css'
import '../static/css/style.css'

import '../static/css/events.css'
import "react-toastify/dist/ReactToastify.css";
import { CHECK_PHONE_NUMBER_EXISTS, TOKEN } from '../vars';
import { getLocalKeyValue, setSessionKeyValue } from "../storage";
import Router from "next/router"; // Import Next.js Router
import PhoneInputComponent from '../pages/PhoneInput'; // Import the PhoneInput component

//import "bootstrap/dist/css/bootstrap.min.css";
// Sentry.init(
//   { dsn: SENTRY_DSN }
// );



class MyApp extends App {
  state = {
    latitude: null,
    longitude: null,
    error: null,
    loading: true,  // Initialize with loading state
    showModal: false, // Initially show the modal
    errorMessage: "", // Error message to display in the modal
  };

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  async componentDidMount() {
    await this.getGeolocationByIP();  // Only use IP-based geolocation
    if (getLocalKeyValue(TOKEN)) {
      // Initial call after 5 seconds
      setTimeout(this.handleApiRequest.bind(this), 5000);
    
      // Listen for route changes and trigger API call again
      // Route changes also trigger after 5 seconds
      Router.events.on("routeChangeComplete", () => {
        setTimeout(this.handleApiRequest.bind(this), 5000);
      });
    }
  }

  componentWillUnmount() {
    // Cleanup event listener when component unmounts
    Router.events.off("routeChangeComplete", this.handleApiRequest);
  }

  // Function to close the modal
  handleClose = () => {
    this.setState({ showModal: false });
  };

  // API Call Function
  handleApiRequest = async () => {
    console.log("Checking if phone number exists..."); 

  //console.log("Token:", getLocalKeyValue(TOKEN));

    try{
        GET(CHECK_PHONE_NUMBER_EXISTS, getLocalKeyValue(TOKEN))
        .then((check_res) => {
            console.log("Full API Response:", check_res); 

            if (!check_res || !check_res.status) {
                console.error("Invalid response format:", check_res);
                return;
            }
            
            let status = check_res.status; // Ensure this matches API response field
            console.log("Status Code:", status);
            let status_message = check_res.data.message; // Ensure this matches API response field
            console.log("Status Message:", status_message);

            if (status === 200) {
                console.log("Phone Number Exists!");
                this.setState({ showModal: false }); // Hide modal if success
            } else {
                console.log("Phone Number not Exist or unknown status.");
                this.setState({ 
                  errorMessage: "An unexpected error occurred. Please try again.",
                  showModal: true 
                }); // Hide modal if success
            }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.setState({
              errorMessage: "An unexpected error occurred. Please try again.",
              showModal: true, // Show modal if API fails
            });
          }
        });
    } catch (err) {
      this.setState({
        errorMessage: "An unexpected error occurred. Please try again.",
        //showModal: true, // Show modal if API fails
      });
    }
  };

  getGeolocationByIP = async () => {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      const { latitude, longitude } = response.data;

      this.setState({
        latitude,
        longitude,
        error: null,
        loading: false,  // Stop loading once location is fetched
      });
    } catch (error) {
      this.setState({
        error: "Failed to retrieve geolocation by IP.",
        loading: false,  // Stop loading if there's an error
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Sentry.withScope((scope) => {
    //   Object.keys(errorInfo).forEach((key) => {
    //     scope.setExtra(key, errorInfo[key]);
    //   });

    //   Sentry.captureException(error);
    // });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    const { latitude, longitude, error, loading, showModal, errorMessage } = this.state;



    if (loading) {
      return <div></div>;  // Show a loading indicator
    }

    return (
      <>
        <Component
          {...pageProps}
          latitude={latitude}
          longitude={longitude}
          geolocationError={error}
        />
        <ToastContainer />

        <Modal show={showModal} onHide={this.handleClose} centered>
          <Modal.Body className="px-3 py-4 p-sm-5 text-center">
            {/* <h5 className="text-danger">Error</h5>
            <p>{errorMessage}</p> */}

            

            {/* Close Button */}
            
            <div className='px-3 py-2 close-btn' style={{ position: "absolute", top: '0px', right: '0'}}>
              <FontAwesomeIcon icon={faTimes} className="text-danger cursor-pointer" onClick={this.handleClose} />
              {/* <FontAwesomeIcon icon={faTimes} className="text-success" onClick={() => setShowModal(false)}/> */}
            </div>

            {/* Phone Input inside Modal */}
            <PhoneInputComponent />

          </Modal.Body>
        </Modal>

        {/*<Modal  show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Body className="px-3 py-4 p-sm-5 d-flex justify-content-between">
          <div className="mb-0 text-center">
            <div className="d-flex justify-content-center mt-3">d
            </div>
          </div>
          <div className='px-3 py-2 close-btn' style={{marginTop: '-26px'}}>
          <FontAwesomeIcon icon={faTimes} className="text-danger" onClick={this.handleClose} />
          </div>
          </Modal.Body>
        </Modal> */}

        <div className="button-bar">
          <button
            className="stickybtn"
            onClick={() => window.open("https://api.whatsapp.com/send?phone=918885558235", "_blank")}
          >
            <i className="fa fa-headphones" aria-hidden="true"></i> Talk to Experts
          </button>
        </div>
      </>
    );
  }
}

// export default withNProgress()(MyApp);
export default MyApp









