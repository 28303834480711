// components/PhoneInput.js
import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { GET, POST } from "../helpers";
import { UPDATE_USER_CONTACT_NUMBER, TOKEN } from '../vars';
import { getLocalKeyValue, setSessionKeyValue } from "../storage";
import Router from "next/router";

const PhoneInputComponent = () => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return 'Phone number is required';
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      return 'Invalid phone number for the selected country';
    }

    const countryCode = phoneNumber.slice(0, 2);
    const phoneNumberWithoutCountryCode = phoneNumber.slice(2);

    if (countryCode === '+1') { // United States
      if (phoneNumberWithoutCountryCode.length !== 10) {
        return 'US phone numbers must be 10 digits';
      }
    } else if (countryCode === '+91') { // India
      if (phoneNumberWithoutCountryCode.length !== 10) {
        return 'Indian phone numbers must be 10 digits';
      }
    } else if (countryCode === '+44') { // United Kingdom
      if (phoneNumberWithoutCountryCode.length !== 10) {
        return 'UK phone numbers must be 10 digits';
      }
    }

    return ''; // No error
  };

  const handleChange = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
  };

  const handleSubmit = async () => {
    const errorMessage = validatePhoneNumber(phoneNumber);

    if (errorMessage) {
      toast.error(errorMessage); // Show toast only on submit
      return;
    }else{
        toast.success("Phone number is Validated"); // Show toast only on submit
    }

    console.log('Submitting phone number:', phoneNumber); //return false;

    setIsSubmitting(true);

    try {
        // Simulate API request
        console.log('Submitting payload:', phoneNumber); //return false;

        // Make API request
        let data = {
            contact_number: phoneNumber
        };
        console.log("Data:", data); //return false;
        POST(UPDATE_USER_CONTACT_NUMBER, getLocalKeyValue(TOKEN), data).then((check_res)=>{
            console.log("Full API Response:", check_res); 
            // Handle API response
            if (check_res.status === 200) {
                toast.success("Phone number submitted successfully!");
                //window.location.href = "/profile"; // Redirect to home page
                Router.push("/"); // Redirect to home page
            } else {
                toast.error('Failed to submit phone number. Please try again.');
            }
        }).catch((error)=>{
            console.error('Error submitting phone number:', error);
            toast.error('An error occurred. Please try again.');
        });      
    } catch (error) {
      console.error('Error submitting phone number:', error);
      toast.error('An error occurred. Please try again.1111');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className=" p-4" style={{ width: '100%', maxWidth: '400px' }}>
        <h1 className="h3 mb-4 text-center">Please Update Your Contact Details</h1>
        <div className="mb-3">
          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={handleChange}
            defaultCountry="IN"
            className="form-control"
          />
        </div>
        {/* {error && <div className="alert alert-danger">{error}</div>} */}
        <button
          onClick={handleSubmit}
          className="btn btn-primary w-100"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default PhoneInputComponent;